/* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/* checkbox styles */
.mdl-demo .dropdown-trigger>span:after {
  font-size: 12px;
  color: #555;
}

.mdl-demo .toggle {
  font: normal normal normal 24px/1 "Material Icons";
}

.mdl-demo .toggle.collapsed::after {
  cursor: pointer;
  content: "\e145";
  vertical-align: middle;
}

.mdl-demo .toggle.expanded::after {
  cursor: pointer;
  content: "\e15b";
  vertical-align: middle;
}

.mdl-demo .checkbox-item {
  position: relative;
  width: 2rem;
  height: 2rem;
  margin-right: 0rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.mdl-demo .checkbox-item:before {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  font: normal normal normal 18px/1 "Material Icons";
  color: #555;
  white-space: pre;
  margin-right: 4px;
  border-color: #333842;
  cursor: pointer;
  content: "\e835";
  vertical-align: middle;
  font-size: 24px;
}

.mdl-demo .checkbox-item:checked:before {
  font: normal normal normal 18px/1 "Material Icons";
  color: #333842;
  white-space: pre;
  margin-right: 4px;
  border-color: #333842;
  cursor: pointer;
  content: "\e834";
  vertical-align: middle;
  font-size: 24px;
}

.dropdown-content {
  max-height: 400px;
  overflow-y: auto;
  padding: 0 !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  width: 100%;
  border-radius: 4px;
  margin-top: -4px;
}

/* Tag */
.tag {
  border: 0 !important;
  height: 32px !important;
  border-radius: 16px !important;
  padding-left: 12px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #e0e0e0 !important;
  font-size: 16px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.tag-item .search {
  border: 0 !important;
}

.tag-remove {
  color: rgba(0, 0, 0, 0.26) !important;
  font-size: 20px !important;
  background: rgba(0, 0, 0, 0.26) !important;
  padding: 0 0 0 0 !important;
  margin: 5px 8px 0 8px !important;
  width: 18px !important;
  height: 18px !important;
  line-height: 14px !important;
  font-weight: bold;
  color: #e0e0e0 !important;
  font-size: 14px !important;
  border-radius: 50% !important;
}

/* Node */
.node {
  padding: 0 0 !important;
  line-height: 50px !important;
  font-size: 16px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.node.checked {
  background: #ebebeb !important;
}

.node>label {
  padding: 0 !important;
  margin: 0 !important;
}

/* Infinite Scroll */
.infinite-scroll-component li:hover {
  background: #f5f5f5 !important;
}

.react-dropdown-tree-select .dropdown {
  width: 100%;
}

.infinite-scroll-component li[aria-level="1"] {
  padding: 0 10px !important;
}

.infinite-scroll-component li[aria-level="1"] label {
  width: calc(100% - 40px);
}

.infinite-scroll-component li[aria-level="2"] {
  padding-left: 40px !important;
}

.infinite-scroll-component li[aria-level="2"] label {
  width: calc(100% - 50px);
}

.infinite-scroll-component li[aria-level="3"] {
  padding-left: 70px !important;
}

.searchModeOn .infinite-scroll-component li[aria-level="3"] {
  /* padding: 0 10px !important; */
}

.infinite-scroll-component li[aria-level="3"] label {
  width: calc(100% - 70px);
}

/* React Dropdown Tree Select */
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 5px 5px 5px 0 !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  position: absolute;
  right: 8px;
  top: calc(50% - 10px);
  font-size: 7px;
  transform: scalex(1.85);
  color: #757575 !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  position: absolute;
  right: 8px;
  top: calc(50% - 10px);
  font-size: 7px;
  transform: scalex(1.85);
  color: #757575 !important;
}

.no-matches {
  padding: 15px;
  min-height: 50px;
  display: block;
  font-size: 16px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.tag-list .tag-item {
  margin: 3px !important;
}

.tag-item .search {
  font-size: 16px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  color: #6c6c6c !important;
}