$greenColor: #db9400;
$lightGreen: #db940021;
$headerBGColor: linear-gradient(90deg, #309152 1.25%, rgba(173, 68, 196, 0.96) 100%);

// Our Theme Colors
$primaryColor:#db9400;
$secondaryColor: #333842;
$accentColor1: #5d626d;
$accentColor2: #ebfbff;
$accentColor3: #eaecef;

// Alert Colors
// $infoColor: ;
// $successColor: #43a047;
// $warning:;
// $errorColor: #;


// Status Colors
$lowColor: #43a047;
$mediumColor: #fdd835;
$highColor: #fb8c00;
$criticalColor: #e53935;

$whiteColor: #ffffff;
$blackColor: #000000;


// Table Header Color
$tableTh: #f9f9f9;

// toastify-color-light: #fff;
// --toastify-color-dark: #121212;
// --toastify-color-info: #3498db;
// --toastify-color-success: #07bc0c;
// --toastify-color-warning: #f1c40f;
// --toastify-color-error: #e74c3c;

// Solution 1
// .data-panel td {
//     border-bottom: #f9f9f9;
//     font-size: 14px;
//     padding: 0 10px 0 10px;
// }

// padding-right: 13px;

// Solution 2
// .MuiTableSortLabel-icon{
//     color: rgba(0, 0, 0, 0.54);
//     opacity: 1;
// }