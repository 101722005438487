@import "../../../Resources/scss/variables";

.MuiAppBar-root {
    background: none !important;
    border-bottom: 1px solid rgba($whiteColor, 0.5);
}

.main-header {
    background-image: url(../../../assets/images/header.png);
    background-position: center bottom;
    background-size: cover;
    height: 250px;
    background-color: #262626;
}