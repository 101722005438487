.tableContainer {
    width: 100%;
    margin-top: 10px;
}

.formContainer {
    position: relative;
    padding-left: 5px;

    .formBody {
        height: calc(100% - 42px);
        width: 100%;

        .os-scrollbar-horizontal {
            display: none;
        }

        .innerContainer {
            margin-top: 10px; 
            padding: 15px;
        }
        
        .form-control:focus, .ant-input:focus, .ant-input:hover {
            outline: none;
            border-color: unset;
            outline: 0;
            box-shadow: none;
            border: 1px solid #00000045;
        }

        .ant-select-selector {
            height: 40px;

            .ant-select-selection-item {
                padding-top: 5px;
            }
        }

        .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector  {
            border-color: unset;
            box-shadow: none;
            border: 1px solid #00000045;
        }
        
        .form-control, .ant-input {
            border: 1px solid #00000045;
        }
    }

    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 5px;
        border-top: 1px solid #80808038;
        display: flex;
        justify-content: flex-end;

        .ant-btn {
            margin-right: 5px;
            width: auto;
            padding: 0 15px;
        }
    }
}

@media screen and (max-width: 768px) {
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .headerContainer {
        .headerTitle {
            font-size: 15px;
        }
    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td,
    .ant-table tfoot>tr>th,
    .ant-table tfoot>tr>td {
        width: 150px;
    }
}

.actionContainer {
    .anticon {
        padding: 10px;
    }
}

.ant-table-row-indent {
    height: unset;
}

/* field Gap */
.field-gap {
    margin-bottom: 20px !important;
}

/* Poppup Close */
.popup-close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10000;
}