@import "../../../Resources/scss/variables";

.body {
    background-color: #ebfbff !important;
}

a {
    color: $primaryColor;
}

.sideBar {
    display: flex;
    z-index: 1200;
    background: white;
    position: fixed;
    height: 100%;
}

@mixin transition {
    transition-duration: .2s;
    -webkit-transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
    @include transition;
}

@media screen and (min-width: 768px) {
    .sideBarContainerClosed {
        width: 100px;
        @include transition;
    }

    .mainContainerClosed {
        width: calc(100% - 100px);
        margin-left: 100px;
    }

    .sideBarContainerOpened {
        width: 240px;
        @include transition;

        .ant-collapse {
            // min-width: 220px;
        }
        .navLinkName{
            min-width: 160px;
        }
    }

    .mainContainerOpened {
        width: calc(100% - 240px);
        margin-left: 240px;
        @include transition;
    }
}

@media screen and (max-width: 230px) {
    .mainContainer {
        position: fixed;
        height: 100%;
    }
}

@media screen and (max-width: 768px) {
    .sideBar {
        top: 0;
        height: 100%;
        position: fixed;
    }

    .mainContainer {
        margin-left: 0;
        height: 100%;
    }

    .sideBarContainerClosed {
        width: 0;
        max-width: 0;
        overflow: hidden;
        @include transition;

    }

    .mainContainerClosed {
        transition: 0s;
        width: 100%;
    }

    .sideBarContainerOpened {
        width: 240px;
        @include transition;
    }

    .mainContainerOpened {
        transition: 0s;
        width: 100%;
    }
}

.renderingContainer {
    max-width: 1800px;
    margin-right: auto;
    margin-left: auto;
    min-height: calc(100% - 65px);
    padding: 15px 10px 55px 10px;
    margin-top: -185px;

    @media (min-width: 768px) {
        padding: 20px 15px 70px 15px;
    }

    @media (min-width: 992px) {
        padding: 20px 25px 70px 25px;
    }

}

footer {
    @include transition;
}

.MuiBreadcrumbs-separator {
    margin: 0 1px !important;
}

.logo-margin {
    margin-left: 10px;
    @include transition;
}