// .inner-table {
//     table {
//         width: 96%;
//         margin: 0 auto;
//     }
// }

.data-panel {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #d7d7d7;

  thead {
    tr {
      background-color: #f9f9f9;
      border-bottom: 1px solid #ece9e9;
    }
  }

  tr {
    border-bottom: 1px solid #f9f9f9;

    &:hover {
      background: #f7f7f7;
    }
  }

  th {
    padding: 0px 6px;
    //border-bottom: #d7d7d7;
    font-size: 14px;
    font-weight: bold;
    height: 46px;
  }

  td {
    padding: 3px 6px;
    //border-bottom: #f9f9f9;
    font-size: 14px;
    word-break: break-word;

    div{
      padding: 3px 6px;
    }
  }
  .table-container {
    box-shadow: none;
  }

  .plusMinus-table {
    table {
      width: calc(100% - 40px);
      margin: 0 auto 0 40px;
    }

    &:hover {
      background-color: white !important;
    }
  }

  .checkPlusMinus-table {
    table {
      width: calc(100% - 100px);
      margin: 0 auto 0 100px;
    }

    &:hover {
      background-color: white !important;
    }
  }

  .checkPlusMinus-table1 {
    table {
      width: calc(100% - -230px);
      margin: 0 auto 0 100px;
    }

    &:hover {
      background-color: white !important;
    }
  }

  .normal-font {
    font-weight: normal !important;
  }
}


// .search-field {
//     padding: 10px 25px;

//     form {
//         box-shadow: none;
//         border-bottom: 1px solid #ccc;
//         height: 40px;

//         input {
//             font-size: 13px;
//         }
//     }
// }
