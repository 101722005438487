@import "../../../Resources/scss/variables";

.page-title {
    margin: 0;
    font-family: 'Outfit', sans-serif;
    h2 {
        color: #fff;
        font-weight: 400;
        font-size: 38px;
        margin-bottom: 0px;
        margin-top: 5px;
        line-height: 40px;
    }
}

.dummy-btn {
    background: #db9400 !important;
    border-radius: 20px;
    height: 37px !important;
    text-align: center;
    line-height: 37px;
    color: white !important;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
    padding: 10px 15px !important;
}

.reset-color {
    background: #eaecef !important;
    color: #333842 !important;
    &:hover {
        background-color: #A3A5A7 !important;
    }
}
.save-color {
    background: #333842 !important;
    color: white !important;
    &:hover {
        background-color: #23272E !important;
    }
}

.custom-breadcrumb ol {
    margin-top: 5px;

    li {
        color: #fff;
        font-size: 13px;
        font-weight: 200;

        p {
            color: #fff;
            font-weight: 200;
            font-size: 13px;
        }

        a:hover {
            color: $primaryColor;
        }
    }
}

.serchByKeyword {
    width: calc(100% - 30px) !important;
    max-width: 300px;
    position: relative;

}

.serchByClear {
    position: absolute;
    top: 5px;
    right: 0px;
    background-color: #fff;
}

.MuiTableCell-root {
    padding: 0 6px !important;
}