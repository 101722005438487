@import "../../../Resources/scss/variables";

.loginPage {
    background-color: #333842;
    background: url('../../../Resources/images/cru-login-bg-min.jpg') no-repeat center bottom fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 0.75rem !important;
    overflow: auto;

    .float-label {
        flex: 1;
    }

    .fieldIcon {
        position: absolute;
        right: -9px;
        top: 9px;
    }

    .show-password {
        font-size: 14px;
    }

    .forgotPassword {
        color: #00519c !important;
        text-decoration: none;
        font-size: 14px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .loginArea {
        background-color: #FFFFFF;
        border-radius: 5px;
        margin: 25px 0;

        .welcomeMessage {

            .title {
                font-size: 24px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0em;
                display: block;
            }

            .message {
                font-size: 13px;
            }
        }

        .subheading {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
            font-size: 18px;
            font-weight: 600;
            color: #00519c;
        }

        .loginInput {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
            color: rgba(0, 0, 0, 0.87);
            border-radius: 4px;
            border-style: solid;
            border-width: 1px;
            border-color: rgba(0, 0, 0, 0.23);
            min-height: 40px;
            padding: 0 8px;
            font-size: 0.83rem !important;
        }
    }

    .copyright-text {
        font-size: 13px;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }


    }
    .passwordChecklist{
        padding: 0;
        li{
            list-style: none;
            font-size: 13px;
            .MuiSvgIcon-root{
                font-size: 17px !important;
            }
        }
    }
}