.loader {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(173, 205, 207, 0.781);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-text {
    font-weight: 600;
}

.spinner-grow {
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin: 2px;
}