footer {
    margin: -46px 0 0 0;
    background: white;
    padding: 12px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 25%);
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - 100px);
    font-size: 12px;
}

.mainContainerOpened {
    @media (max-width: 768px) {
        footer {
            width: calc(100% - 0px);
        }
    }

    @media (min-width: 992px) {
        footer {
            width: calc(100% - 240px);
        }
    }
}

.mainContainerClosed {
    @media (max-width: 768px) {
        footer {
            width: calc(100% - 0px);
        }
    }

    @media (min-width: 992px) {
        footer {
            width: calc(100% - 100px);
        }
    }
}