.appInitializedComponent {
    height: 100vh;
}
body{
    background-color: #ebfbff !important;
}

.Component-title-22{
    color : #000;
}

