.float-label {
    position: relative;
    margin-bottom: 12px;

    label {
        font-size: 12px;
        font-weight: 600;
    }
}

.label {
    font-size: 12px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    // left: 12px;
    left: 6px;
    top: 12px;
    transition: 0.2s ease all;
}

.label-float {
    top: -9px;
    font-size: 10px;
    background: white;
    padding: 0 2px;
}

.multi-select-container {
    width: 100%;
    position: relative;

    .dropdown-container {
        max-height: 300px;
        background: white;
        border: 1px solid #80808040;
        margin-top: 1px;
        -webkit-box-shadow: 0 0 5px 2px #fff;
        -moz-box-shadow: 0 0 5px 2px #fff;
        box-shadow: 0 2px 12px 2px #ac9e9e52;
        padding: 5px;
        position: absolute;
        z-index: 5;
        width: 100%;
    }

    .overlay-scrollbar-container {
        max-height: calc(100% - 6px);
        width: 100%;

        .os-scrollbar-horizontal {
            display: none;
        }
    }

    .none {
        display: none !important;
    }

    .option-name {
        padding: 5px;
        cursor: pointer;

        .icon {
            margin-top: 4px;
            margin-right: 4px;
        }
    }
}
Button{
 box-shadow: none !important;
}