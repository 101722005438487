.overAllDetailsDataTable{
    table{
        thead{
            tr{
                th:nth-child(4),
                th:nth-child(5),
                th:nth-child(6),
                th:nth-child(7){
                    z-index: 9 !important;
                }
                }
        }
        tbody{
            tr{
            td:nth-child(4),
            td:nth-child(5),
            td:nth-child(6),
            td:nth-child(7){
                z-index: 2 !important;
                background-color: #fff !important;
            }
            }
        }
    }
}