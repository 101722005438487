@import "bootstrap/dist/css/bootstrap.min.css";
@import "overlayscrollbars/css/OverlayScrollbars.css";
@import "react-toastify/dist/ReactToastify.css";
@import "./Resources/scss/index.scss";

.headerContainer {
  padding-right: 15px;
  padding-left: 15px;
}

.button {
  font-style: normal;
  // font-weight: 700 !important;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15em;
  background: $greenColor;
  color: #ffffff;
  padding: 0 15px;
  width: 90px;
}

button {
  font-weight: 600 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: $greenColor;
  color: $greenColor;
}

.ant-btn {
  background-color: $greenColor;
}

.ant-table-filter-dropdown-btns {
  .ant-btn {
    color: #ffffff;
  }

  .ant-btn:hover {
    background: white;
    color: $greenColor;
  }

  .ant-btn-link[disabled] {
    color: $greenColor;
  }
}

.transButton {
  background-color: #fafafa !important;
  color: $greenColor;
}

.ant-pagination-options-quick-jumper {
  cursor: pointer;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  border-color: $greenColor;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $greenColor;
}

.errorMessage {
  color: red;
  font-size: 10px;
  font-weight: 600;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: $greenColor;
}

.searchContainer {
  .ant-btn .anticon {
    color: white;
  }

  .ant-btn:focus .anticon,
  .ant-btn:hover .anticon {
    color: $greenColor;
  }
}

.fullScreen {
  z-index: 9999 !important;

  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.addButton {
  letter-spacing: unset;
  width: auto;
}

.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}

[data-theme="dark"] .site-form-in-drawer-wrapper {
  border-top: 1px solid #303030;
  background: #1f1f1f;
}

.ant-select-dropdown {
  z-index: 9999 !important;
}

.ant-select-multiple {
  .ant-select-selector {
    height: 55px !important;
  }

  .ant-select-selection-item {
    height: 35px !important;
  }
}

.custom-checkbox-container {
  .custom-checkbox {
    width: 17px;
    height: 17px;
    accent-color: $greenColor;
  }

  label {
    position: relative;
    left: 3px;
    top: -3px;
  }
}

input:disabled {
  background: transparent !important;
}

.view-form-control {
  padding-bottom: 2px;

  .view-form-control-label {
    margin-right: 6px;
    font-weight: 600;
    min-width: 25%;
  }

  .view-form-control-value {
    word-break: break-all;
  }
}

.ant-drawer-header {
  padding: 16px 10px;
}

.MuiTablePagination-toolbar {
  p {
    margin: 0;
  }
}

.statusChip {
  min-width: 65px !important;
  color: #fff !important;
  text-align: center;
}

.MuiTablePagination-root {

  p,
  .MuiSelect-select {
    font-weight: bold !important;
  }
}

.MuiInputBase-root {
  .MuiChip-root {
    font-size: 16px;
  }
}

.MuiBackdrop-root {
  //backdrop-filter: blur(4px);
}

.MuiTableHead-root {
  th {
    background: #f9f9f9;
    font-weight: bold;
  }
}

.MuiSwitch-track {
  background-color: #666666 !important;
}

.cruChip {
  .MuiChip-root {
    min-width: 65px !important;
    color: #fff !important;
    text-align: center;
  }
}
td{
  word-break: break-word;
}
.reset-color {
  background: #eaecef !important;
  color: #333842 !important;
  &:hover {
      background-color: #A3A5A7 !important;
  }
}
.save-color {
  background: #333842 !important;
  color: white !important;
  &:hover {
      background-color: #23272E !important;
  }
}

.save-color2 {
  background: #333842 !important;
  color: white !important;
  padding: 4px 10px !important;
  &:hover {
      background-color: #23272E !important;
  }
}
.add-color {
  background: #43a047 !important;
  color: white !important;
  padding: 4px 10px !important;
  &:hover {
      background-color: #2E7031 !important;
  }
}
.cancel-color {
  background: #eaecef !important;
  color: #333842 !important;
  padding: 4px 10px !important;
  &:hover {
      background-color: #A3A5A7 !important;
  }
}

.MuiBreadcrumbs-li{
  a{
    color: #fff !important;

    &:hover{
      text-decoration: underline;
    }
  }
}